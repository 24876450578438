<template>
  <div class="text-center">
    <v-dialog v-model="showModel" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Add Banner</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="bannerForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :rules="[v => !!v || 'Events is required']"
                    label="Events *"
                    item-text="name"
                    item-value="_id"
                    v-model="addBannerForm.event_id"
                    :items="allEvent"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Thumb *"
                    dense
                    :rules="[v => !!v || 'Thumb is required',v => (v && v.size > 0) || 'Thumb is required']"
                    v-model="addBannerForm.thumb"
                    prepend-icon="mdi-camera"
                    @change="previewImage('thumb')"
                  ></v-file-input>
                  <v-img
                    v-if="previewThumbImage"
                    :lazy-src="previewThumbImage"
                    max-height="150"
                    max-width="250"
                    :src="previewThumbImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="WhatsApp Status & Insta Stories (1080 x 1920) *"
                    dense
                    :rules="[v => !!v || 'WhatsApp Status & Insta Stories (1080 x 1920) is required',v => (v && v.size > 0) || 'WhatsApp Status & Insta Stories (1080 x 1920) is required']"
                    v-model="addBannerForm.whatsAppInstaStories"
                    prepend-icon="mdi-camera"
                    @change="previewImage('whatsApp-insta-stories')"
                  ></v-file-input>
                  <v-img
                    v-if="previewWhatsAppInstaStoriesImage"
                    :lazy-src="previewWhatsAppInstaStoriesImage"
                    max-height="150"
                    max-width="250"
                    :src="previewWhatsAppInstaStoriesImage"
                  ></v-img>
                </v-col>
                <!-- <v-col cols="6">
                  <v-file-input
                    label="Facebook Post (1200 x 630) *"
                    dense
                    :rules="[v => !!v || 'Facebook Post (1200 x 630) is required',v => (v && v.size > 0) || 'Facebook Post (1200 x 630) is required']"
                    v-model="addBannerForm.facebookPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('facebook-post')"
                  ></v-file-input>
                  <v-img
                    v-if="previewFacebookPostImage"
                    :lazy-src="previewFacebookPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewFacebookPostImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Instagram Post (1080 x 1080) *"
                    dense
                    :rules="[v => !!v || 'Instagram Post (1080 x 1080) is required',v => (v && v.size > 0) || 'Instagram Post (1080 x 1080) is required']"
                    v-model="addBannerForm.instagramPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('instagram-post')"
                  ></v-file-input>
                  <v-img
                    v-if="previewInstagramPostImage"
                    :lazy-src="previewInstagramPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewInstagramPostImage"
                  ></v-img>
                </v-col> -->
                <v-col cols="6">
                  <v-file-input
                    label="Twitter Post (1200 x 675) *"
                    dense
                    :rules="[v => !!v || 'Twitter Post (1200 x 675) is required',v => (v && v.size > 0) || 'Twitter Post (1200 x 675) is required']"
                    v-model="addBannerForm.twitterPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('twitter-post')"
                  ></v-file-input>
                  <v-img
                    v-if="previewTwitterPostImage"
                    :lazy-src="previewTwitterPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewTwitterPostImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="LinkedIn post (1200 x 1200 ) *"
                    dense
                    :rules="[v => !!v || 'LinkedIn post (1200 x 1200 ) is required',v => (v && v.size > 0) || 'LinkedIn post (1200 x 1200 ) is required']"
                    v-model="addBannerForm.linkedInPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('linkedin-post')"
                  ></v-file-input>
                  <v-img
                    v-if="previewLinkedInPostImage"
                    :lazy-src="previewLinkedInPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewLinkedInPostImage"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    :items="allLanguages"
                    :rules="[v => !!v || 'Language is required']"
                    label="Language"
                    v-model="addBannerForm.language"
                    required
                    chips
                  >
                    <template v-slot:item="{item}">
                      {{item.charAt(0).toUpperCase() + item.slice(1)}}
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetAddBannerModal()">
            Close
          </v-btn>
          <v-btn color="primary" @click="saveBanner()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../../Snackbar.vue";
export default {
  name: "createBanner",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    allLanguages: {
      type: Array,
      default: null,
    }
  },
  data: () => ({
    addBannerForm:{
      event_id:'',
      thumb: [],
      whatsAppInstaStories:[],
      facebookPost:[],
      instagramPost:[],
      twitterPost:[],
      linkedInPost:[],
      language:'',
    },
    allEvent:[],
    valid: true,
    previewThumbImage:'',
    previewWhatsAppInstaStoriesImage:'',
    previewFacebookPostImage:'',
    previewInstagramPostImage:'',
    previewTwitterPostImage:'',
    previewLinkedInPostImage:''
  }),
  watch: { 
    showModel(val){
      if(val){
        this.getAllEventList(); 
      }
    },
  },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    resetAddBannerModal() {
      this.$refs.bannerForm.reset();
      this.allEvent = [],
      this.valid =  true,
      this.previewThumbImage = '',
      this.previewWhatsAppInstaStoriesImage = '',
      // this.previewFacebookPostImage = '',
      // this.previewInstagramPostImage = '',
      this.previewTwitterPostImage = '',
      this.previewLinkedInPostImage = ''
      this.$emit('close');
    },
    saveBanner() {
      if (this.$refs.bannerForm.validate()) {
        let formData = new FormData();
        formData.append('event_id', this.addBannerForm.event_id);
        formData.append('thumb', this.addBannerForm.thumb);
        formData.append('language', this.addBannerForm.language);
        formData.append('whatsApp_status_insta_stories', this.addBannerForm.whatsAppInstaStories);
        // formData.append('facebook_post', this.addBannerForm.facebookPost);
        // formData.append('instagram_post', this.addBannerForm.instagramPost);
        formData.append('twitter_post', this.addBannerForm.twitterPost);
        formData.append('linkedin_post', this.addBannerForm.linkedInPost);
        axios.post(this.Node_JS_HOST + "/api/v1/web/banner-master/upload-banner",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              this.resetAddBannerModal();
              this.snackbarText = "Banner added Successfully";
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    getAllEventList(){
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/event")
      .then((res) => {
          const response = res.data;
          this.allEvent = response.data.allEvent;
      })
    },
    previewImage: function(fileInput) {
      var reader = new FileReader();
      if(fileInput === 'thumb'){
        if(this.addBannerForm.thumb){
          reader.onload = (e) => {
            this.previewThumbImage = e.target.result;
          }
          reader.readAsDataURL(this.addBannerForm.thumb);
        }else{
          this.previewThumbImage = '';
        }
      }
      if(fileInput === 'whatsApp-insta-stories'){
        if(this.addBannerForm.whatsAppInstaStories){
          reader.onload = (e) => {
            this.previewWhatsAppInstaStoriesImage = e.target.result;
          }
          reader.readAsDataURL(this.addBannerForm.whatsAppInstaStories);
        }else{
          this.previewWhatsAppInstaStoriesImage = '';
        }
      }

      // if(fileInput === 'facebook-post'){
      //   if(this.addBannerForm.facebookPost){
      //     reader.onload = (e) => {
      //       this.previewFacebookPostImage = e.target.result;
      //     }
      //     reader.readAsDataURL(this.addBannerForm.facebookPost);
      //   }else{
      //     this.previewFacebookPostImage = '';
      //   }
      // }

      // if(fileInput === 'instagram-post'){
      //   if(this.addBannerForm.instagramPost){
      //     reader.onload = (e) => {
      //       this.previewInstagramPostImage = e.target.result;
      //     }
      //     reader.readAsDataURL(this.addBannerForm.instagramPost);
      //   }else{
      //     this.previewInstagramPostImage = '';
      //   }
      // }

      if(fileInput === 'twitter-post'){
        if(this.addBannerForm.twitterPost){
          reader.onload = (e) => {
            this.previewTwitterPostImage = e.target.result;
          }
          reader.readAsDataURL(this.addBannerForm.twitterPost);
        }else{
          this.previewTwitterPostImage = '';
        }
      }
 
      if(fileInput === 'linkedin-post'){
        if(this.addBannerForm.linkedInPost){
          reader.onload = (e) => {
            this.previewLinkedInPostImage = e.target.result;
          }
          reader.readAsDataURL(this.addBannerForm.linkedInPost);
        }else{
          this.previewLinkedInPostImage = '';
        }
      }
      console.clear();  
    },
  },
};
</script>