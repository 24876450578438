<template>
  <v-container fluid class="down-top-padding">
    <v-row>
      <v-col cols="12" sm="12">
        <v-card class="mb-7">
          <v-toolbar flat>
            <v-toolbar-title> Upload Banner </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn @click="addBannerModal = true" class="mx-2" fab dark small color="#1976d2">
              <v-icon dark> mdi-plus </v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <div class="pa-4">
            <v-data-table
              class="mx-5 elevation-1"
              :loading="tableLoading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="serverItems"
              :options.sync="options"
              :server-items-length="totalItems"
              item-value="name"
              :search="search"
              :footer-props="{
                'items-per-page-options': [50, 100, 150, 200, 300]
              }"
              @update:options="getBanner"
              :custom-filter="filterOnlyCapsText"
              >
              <template v-slot:top>
                <v-text-field
                 v-model="search"
                 label="Search (Event Details)"
                 class="mx-4">
              </v-text-field>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td>{{ row.item._id }}</td>
                  <td>{{ row.item.event_id.name }}</td>
                  <td>
                    <div class="d-flex">
                      <a @click="showImageModel(row.item.thumb)" style="text-decoration: none;">
                        <v-img :src="imageBase + row.item.thumb" :alt="row.item.event_id.name" height="25px"
                          width="25px"></v-img>
                      </a>
                      <span class="ml-2">
                        <v-img :src="s3ImageBase + '/' + row.item.thumb" :alt="'preview-cdn'" height="25px"
                          width="25px"></v-img>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <a @click="showImageModel(row.item.whatsApp_status_insta_stories)" style="text-decoration: none;">
                        <v-img :src="imageBase + row.item.whatsApp_status_insta_stories" :alt="row.item.event_id.name"
                          height="25px" width="25px"></v-img>
                      </a>
                      <span class="ml-2">
                        <v-img :src="s3ImageBase + '/' + row.item.whatsApp_status_insta_stories" :alt="'preview-cdn'"
                          height="25px" width="25px"></v-img>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <a @click="showImageModel(row.item.twitter_post)" style="text-decoration: none;">
                        <v-img :src="imageBase + row.item.twitter_post" :alt="row.item.event_id.name" height="25px"
                          width="25px"></v-img>
                      </a>
                      <span class="ml-2">
                        <v-img :src="s3ImageBase + '/' + row.item.twitter_post" :alt="'preview-cdn'" height="25px"
                          width="25px"></v-img>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex">
                      <a @click="showImageModel(row.item.linkedin_post)" style="text-decoration: none;">
                        <v-img :src="imageBase + row.item.linkedin_post" :alt="row.item.event_id.name" height="25px"
                          width="25px"></v-img>
                      </a>
                      <span class="ml-2">
                        <v-img :src="s3ImageBase + '/' + row.item.linkedin_post" :alt="'preview-cdn'" height="25px"
                          width="25px"></v-img>
                      </span>
                    </div>
                  </td>
                  <td>{{ row.item.language }}</td>
                  <td>{{ dateFormat(row.item.created_date) }}</td>
                  <td><a @click="updateBannerModel(row.item)"><v-icon color="primary">mdi-pencil</v-icon></a> | <a
                      @click="showDeleteDilog(row.item)"><v-icon color="red">mdi-delete</v-icon></a></td>
                </tr>
              </template>
              <template v-slot:no-results>NO RESULTS HERE</template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <snackbar :text="snackbarText" :showSnackbar="isShowSnackbar" @hide="isShowSnackbar = false"></snackbar>
    <create-banner :show-model="addBannerModal" :all-languages="allLanguages" @close="closeBannerModal"></create-banner>
    <delete-dialog :contentText="deleteText" :showModel="showDeleteModel" :id="deleteId" :headerText="'Delete Banner'"
      @action="deleteAction"></delete-dialog>
    <update-banner :show-model="updateBannerModal" :all-languages="allLanguages" @close="closeBannerModal"
      :banner-detail="updateBannerDetail" :image-base="imageBase"></update-banner>
    <image-model :imageUrl="imageFullUrl" :showModel="isShowImageModel" :headerText="'Image'"
      @hide="isShowImageModel = false"></image-model>
  </v-container>
</template>
<script>
import axios from "axios";
import Snackbar from '../../Snackbar.vue';
import CreateBanner from './CreateBanner.vue';
import UpdateBanner from './UpdateBanner.vue';
import DeleteDialog from '../../DeleteDialog.vue';
import ImageModel from '../../ImageModel.vue';

export default {
  name: "Banners",
  components: {
    Snackbar,
    ImageModel,
    CreateBanner,
    DeleteDialog,
    UpdateBanner
  },
  data: () => ({
    options: {
      page: 1,
      itemsPerPage: 50,
      sortBy: [{ key: 'created_date', order: 'desc' }],
    },
    serverItems: [],
    totalItems: 0,
    addBannerModal: false,
    allBanner: [],
    allLanguages: ['gujarati', 'hindi', 'english'],
    headers: [
      { text: "Id", value: " id" },
      { text: "Event", value: "event_id.name", sortable: true },
      { text: "Thumb", value: "thumb", sortable: false },
      { text: "WhatsApp/Insta", value: "whatsApp_status_insta_stories", sortable: false },
      // { text: "Facebook", value: "facebook_post" },
      // { text: "Instagram", value: "instagram_post" },
      { text: "Twitter", value: "twitter_post", sortable: false },
      { text: "Linkedin", value: "linkedin_post", sortable: false },
      { text: "Language", value: "language", sortable: true },
      { text: "Created Date", value: "created_date", sortable: true },
      { text: "Action" },
    ],
    tableLoading: false,
    search: '',
    deleteId: '',
    showDeleteModel: false,
    deleteText: '',
    updateBannerModal: false,
    updateBannerDetail: {},
    imageBase: '',
    s3ImageBase: '',
    imageFullUrl: '',
    isShowImageModel: false,
  }),
  computed: {},
  watch: {
    search() {
      this.options.page = 1; // Reset page to 1 when search query changes
      this.getBanner(this.options);
    },
    'allBanner': function (banners) {
      banners.map(item => { this.allLanguages.push(item.language); });
    }
  },
  mounted: function () {
  },
  created: function () { },
  methods: {
    getBanner() {
      const { page, itemsPerPage, sortBy, sortDesc } = this.options;
      this.tableLoading = false
      const skip = (page - 1) * itemsPerPage;
      const sortByOrder = sortDesc[0] ? "desc" : "asc"
      const column = sortBy[0] && sortBy[0].key ? sortBy[0].key : sortBy[0]
      const searchQuery = this.search.toLowerCase();
      let params = new URLSearchParams();
      params.append('limit', itemsPerPage);
      params.append('skip', skip);

      if (sortByOrder && column) {
        params.append('order', sortByOrder);
        params.append('column', column);
      }
      if (searchQuery) params.append('search', searchQuery)

      const apiUrl = `${this.Node_JS_HOST}/api/v1/web/banner-master/upload-banner?${params.toString()}`;
      axios.get(apiUrl)
        .then((res) => {
          const response = res.data;
          this.serverItems = response.data.allBanner;
          this.imageBase = response.data.imageBase;
          this.s3ImageBase = response.data.s3ImageBase;
          this.totalItems = response.data.counts;
          this.tableLoading = false;
        }).catch(() => {
          this.tableLoading = false;
        })
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    closeBannerModal() {
      this.getBanner(this.options);
      this.addBannerModal = false;
      this.updateBannerModal = false;
    },
    deleteAction(actionType, id) {
      if (actionType == 'delete') {
        axios.delete(this.Node_JS_HOST + "/api/v1/web/banner-master/upload-banner/" + id)
          .then((res) => {
            if (res) {
              this.getBanner(this.options);
              this.snackbarText = 'Banner Deleted';
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = 'Something went wrong please try again';
            this.isShowSnackbar = true;
          });
      }
      this.showDeleteModel = false;
      this.deleteId = '';
    },
    showDeleteDilog(bannerDetail) {
      this.deleteText = 'Are you sure, You want to delete this <b>' + bannerDetail.event_id.name + '</b> Event banner.';
      this.deleteId = bannerDetail._id;
      this.showDeleteModel = true;
    },
    updateBannerModel(bannerDetail) {
      this.updateBannerModal = true;
      this.updateBannerDetail = bannerDetail;
    },
    async showImageModel(imageName) {
      this.imageFullUrl = this.s3ImageBase + `/${imageName}`;
      this.isShowImageModel = true
    },
  },
};
</script>
<style scoped>
::v-deep th {
  white-space: nowrap !important;
}

.w-100 {
  max-width: 100px;
}

.w-unset {
  max-width: unset;
}
</style>