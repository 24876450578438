<template>
  <div class="text-center">
    <v-dialog v-model="showUpdateBannerModal" persistent max-width="800px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Update Banner</span>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="bannerForm"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                    :rules="[v => !!v || 'Events is required']"
                    label="Events *"
                    item-text="name"
                    item-value="_id"
                    v-model="editBannerForm.event_id"
                    :items="allEvent"
                  ></v-autocomplete>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Thumb *"
                    dense
                    :rules="[v => !!v || 'Thumb is required',v => (v && v.size > 0) || 'Thumb is required']"
                    v-model="editBannerForm.thumb"
                    prepend-icon="mdi-camera"
                    @change="previewImage('thumb')"
                    ref="thumbFileUpload"
                  ></v-file-input>
                  <v-img
                    v-if="previewThumbImage"
                    :lazy-src="previewThumbImage"
                    max-height="150"
                    max-width="250"
                    :src="previewThumbImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="WhatsApp Status & Insta Stories (1080 x 1920) *"
                    dense
                    :rules="[v => !!v || 'WhatsApp Status & Insta Stories (1080 x 1920) is required',v => (v && v.size > 0) || 'WhatsApp Status & Insta Stories (1080 x 1920) is required']"
                    v-model="editBannerForm.whatsAppInstaStories"
                    prepend-icon="mdi-camera"
                    @change="previewImage('whatsApp-insta-stories')"
                    ref="whatsAppInstaStoriesFile"
                  ></v-file-input>
                  <v-img
                    v-if="previewWhatsAppInstaStoriesImage"
                    :lazy-src="previewWhatsAppInstaStoriesImage"
                    max-height="150"
                    max-width="250"
                    :src="previewWhatsAppInstaStoriesImage"
                  ></v-img>
                </v-col>
                <!-- <v-col cols="6">
                  <v-file-input
                    label="Facebook Post (1200 x 630) *"
                    dense
                    :rules="[v => !!v || 'Facebook Post (1200 x 630) is required',v => (v && v.size > 0) || 'Facebook Post (1200 x 630) is required']"
                    v-model="editBannerForm.facebookPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('facebook-post')"
                    ref="facebookPostFile"
                  ></v-file-input>
                  <v-img
                    v-if="previewFacebookPostImage"
                    :lazy-src="previewFacebookPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewFacebookPostImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="Instagram Post (1080 x 1080) *"
                    dense
                    :rules="[v => !!v || 'Instagram Post (1080 x 1080) is required',v => (v && v.size > 0) || 'Instagram Post (1080 x 1080) is required']"
                    v-model="editBannerForm.instagramPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('instagram-post')"
                    ref="instagramPostFile"
                  ></v-file-input>
                  <v-img
                    v-if="previewInstagramPostImage"
                    :lazy-src="previewInstagramPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewInstagramPostImage"
                  ></v-img>
                </v-col> -->
                <v-col cols="6">
                  <v-file-input
                    label="Twitter Post (1200 x 675) *"
                    dense
                    :rules="[v => !!v || 'Twitter Post (1200 x 675) is required',v => (v && v.size > 0) || 'Twitter Post (1200 x 675) is required']"
                    v-model="editBannerForm.twitterPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('twitter-post')"
                    ref="twitterPostFile"
                  ></v-file-input>
                  <v-img
                    v-if="previewTwitterPostImage"
                    :lazy-src="previewTwitterPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewTwitterPostImage"
                  ></v-img>
                </v-col>
                <v-col cols="6">
                  <v-file-input
                    label="LinkedIn post (1200 x 1200 ) *"
                    dense
                    :rules="[v => !!v || 'LinkedIn post (1200 x 1200 ) is required',v => (v && v.size > 0) || 'LinkedIn post (1200 x 1200 ) is required']"
                    v-model="editBannerForm.linkedInPost"
                    prepend-icon="mdi-camera"
                    @change="previewImage('linkedin-post')"
                    ref="linkedinPostFile"
                  ></v-file-input>
                  <v-img
                    v-if="previewLinkedInPostImage"
                    :lazy-src="previewLinkedInPostImage"
                    max-height="150"
                    max-width="250"
                    :src="previewLinkedInPostImage"
                  ></v-img>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    :items="allLanguages"
                    :rules="[v => !!v || 'Language is required']"
                    label="Language"
                    v-model="editBannerForm.language"
                    required
                    chips
                  >
                    <template v-slot:item="{item}">
                      {{item.charAt(0).toUpperCase() + item.slice(1)}}
                    </template>
                  </v-combobox>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="resetUpdateBannerModal()">
            Close
          </v-btn>
          <v-btn color="primary" @click="updateBanner()"> Save </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <snackbar
      :text="snackbarText"
      :showSnackbar="isShowSnackbar"
      @hide="isShowSnackbar = false"
    ></snackbar>
  </div>
</template>
<script>
import axios from "axios";
import Snackbar from "../../Snackbar.vue";
export default {
  name: "updateBanner",
  components: {
    Snackbar,
  },
  props: {
    showModel: {
      type: Boolean,
      default: false,
    },
    bannerDetail: {
      type: Object,
    },
    imageBase:{
      type: String
    },
    allLanguages:{
      type: Array,
      default: null,
    }
  },
  data: () => ({
    editBannerForm:{
      event_id:'',
      thumb: [],
      whatsAppInstaStories:[],
      // facebookPost:[],
      // instagramPost:[],
      twitterPost:[],
      linkedInPost:[],
      language:'',
    },
    allEvent:[],
    valid: true,
    showUpdateBannerModal:false,
    previewThumbImage:'',
    previewWhatsAppInstaStoriesImage:'',
    // previewFacebookPostImage:'',
    // previewInstagramPostImage:'',
    previewTwitterPostImage:'',
    previewLinkedInPostImage:''
  }),
  watch: { 
    showModel(val){
      if(val){
        this.getAllEventList();
      }
      this.showUpdateBannerModal = val;
    },
    async bannerDetail(val){
      this.editBannerForm.event_id = val.event_id._id;
      this.editBannerForm.thumb = val.thumb;
      this.editBannerForm.whatsAppInstaStories = val.whatsApp_status_insta_stories;
      // this.editBannerForm.facebookPost = val.facebook_post;
      // this.editBannerForm.instagramPost = val.instagram_post;
      this.editBannerForm.twitterPost = val.twitter_post;
      this.editBannerForm.linkedInPost = val.linkedin_post;
      this.editBannerForm.language = val.language;
      if(this.editBannerForm.thumb){
        var bannerThumbUrl = this.imageBase + this.editBannerForm.thumb;
        var imgName =  bannerThumbUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(bannerThumbUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], imgName, { contentType });
          this.setFileInput(file,'thumb');
          this.previewImage('thumb');
        })
      }


      if(this.editBannerForm.whatsAppInstaStories){
        var whatsAppInstaStoriesUrl = this.imageBase + this.editBannerForm.whatsAppInstaStories;
        var whatsAppInstaStoriesImgName =  whatsAppInstaStoriesUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(whatsAppInstaStoriesUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], whatsAppInstaStoriesImgName, { contentType });
          this.setFileInput(file,'whatsAppInstaStories');
          this.previewImage('whatsApp-insta-stories');
        })
      }

      /*if(this.editBannerForm.facebookPost){
        var faceboookPostUrl = this.imageBase + this.editBannerForm.facebookPost;
        var faceboookPostImgName =  faceboookPostUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(faceboookPostUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], faceboookPostImgName, { contentType });
          this.setFileInput(file,'facebookPost');
          this.previewImage('facebook-post');
        })
      }

      if(this.editBannerForm.instagramPost){
        var instagramPostUrl = this.imageBase + this.editBannerForm.instagramPost;
        var instagramPostImgName =  instagramPostUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(instagramPostUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], instagramPostImgName, { contentType });
          this.setFileInput(file,'instagramPost');
          this.previewImage('instagram-post');
        })
      }*/

      if(this.editBannerForm.twitterPost){
        var twitterPostUrl = this.imageBase + this.editBannerForm.twitterPost;
        var twitterPostImgName =  twitterPostUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(twitterPostUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], twitterPostImgName, { contentType });
          this.setFileInput(file,'twitterPost');
          this.previewImage('twitter-post');
        })
      }

      if(this.editBannerForm.linkedInPost){
        var linkedInPostUrl = this.imageBase + this.editBannerForm.linkedInPost;
        var linkedInPostImgName =  linkedInPostUrl.split(/[#?]/)[0].split("/").pop().trim();
        await fetch(linkedInPostUrl)
        .then(async response => {
          const contentType = response.headers.get('content-type')
          const blob = await response.blob()
          const file = new File([blob], linkedInPostImgName, { contentType });
          this.setFileInput(file,'linkedInPost');
          this.previewImage('linkedin-post');
        })
      }

    },
  },
  computed: {},
  mounted: function () {
  },
  created: function () {},
  methods: {
    resetUpdateBannerModal() {
      this.$refs.bannerForm.reset();
      this.allEvent = [],
      this.valid =  true,
      this.previewThumbImage = '',
      this.previewWhatsAppInstaStoriesImage = '',
      // this.previewFacebookPostImage = '',
      // this.previewInstagramPostImage = '',
      this.previewTwitterPostImage = '',
      this.previewLinkedInPostImage = ''
      this.$emit('close');
    },
    updateBanner() {
      if (this.$refs.bannerForm.validate()) {
        let formData = new FormData();
        formData.append('event_id', this.editBannerForm.event_id);
        formData.append('thumb', this.editBannerForm.thumb);
        formData.append('language', this.editBannerForm.language);
        formData.append('whatsApp_status_insta_stories', this.editBannerForm.whatsAppInstaStories);
        // formData.append('facebook_post', this.editBannerForm.facebookPost);
        // formData.append('instagram_post', this.editBannerForm.instagramPost);
        formData.append('twitter_post', this.editBannerForm.twitterPost);
        formData.append('linkedin_post', this.editBannerForm.linkedInPost);
        formData.append('_id', this.bannerDetail._id);
        axios.put(this.Node_JS_HOST + "/api/v1/web/banner-master/upload-banner",formData,{
          headers: {'content-type': 'multipart/form-data'},
        }).then((res) => {
            if (res) {
              this.resetUpdateBannerModal();
              this.snackbarText = "Banner add Successfully";
              this.isShowSnackbar = true;
            }
          })
          .catch((error) => {
            this.errorHandle(error);
            this.snackbarText = "Please insert valid input";
            this.isShowSnackbar = true;
          });
      }
    },
    getAllEventList(){
      axios.get(this.Node_JS_HOST + "/api/v1/web/banner-master/event")
      .then((res) => {
          const response = res.data;
          this.allEvent = response.data.allEvent;
      })
    },
    setFileInput(file,inputId){
      this.editBannerForm[inputId] = file;
      if(inputId == 'thumb'){
        this.$refs.thumbFileUpload.value = file;
      }
      if(inputId == 'whatsAppInstaStories'){
        this.$refs.whatsAppInstaStoriesFile.value = file;
      }
      // if(inputId == 'facebookPost'){
      //   this.$refs.facebookPostFile.value = file;
      // }
      // if(inputId == 'instagramPost'){
      //   this.$refs.instagramPostFile.value = file;
      // }
      if(inputId == 'twitterPost'){
        this.$refs.twitterPostFile.value = file;
      }
      if(inputId == 'linkedInPost'){
        this.$refs.linkedinPostFile.value = file;
      }
      console.clear();  
    },
    previewImage: function(fileInput) {
      var reader = new FileReader();
      if(fileInput === 'thumb'){
        if(this.editBannerForm.thumb){
          reader.onload = (e) => {
            this.previewThumbImage = e.target.result;
          }
          reader.readAsDataURL(this.editBannerForm.thumb);
        }else{
          this.previewThumbImage = '';
        }
      }

      if(fileInput === 'whatsApp-insta-stories'){
        if(this.editBannerForm.whatsAppInstaStories){
          reader.onload = (e) => {
            this.previewWhatsAppInstaStoriesImage = e.target.result;
          }
          reader.readAsDataURL(this.editBannerForm.whatsAppInstaStories);
        }else{
          this.previewWhatsAppInstaStoriesImage = '';
        }
      }

      /*if(fileInput === 'facebook-post'){
        if(this.editBannerForm.facebookPost){
          reader.onload = (e) => {
            this.previewFacebookPostImage = e.target.result;
          }
          reader.readAsDataURL(this.editBannerForm.facebookPost);
        }else{
          this.previewFacebookPostImage = '';
        }
      }

      if(fileInput === 'instagram-post'){
        if(this.editBannerForm.instagramPost){
          reader.onload = (e) => {
            this.previewInstagramPostImage = e.target.result;
          }
          reader.readAsDataURL(this.editBannerForm.instagramPost);
        }else{
          this.previewInstagramPostImage = '';
        }
      }*/

      if(fileInput === 'twitter-post'){
        if(this.editBannerForm.twitterPost){
          reader.onload = (e) => {
            this.previewTwitterPostImage = e.target.result;
          }
          reader.readAsDataURL(this.editBannerForm.twitterPost);
        }else{
          this.previewTwitterPostImage = '';
        }
      }
 
      if(fileInput === 'linkedin-post'){
        if(this.editBannerForm.linkedInPost){
          reader.onload = (e) => {
            this.previewLinkedInPostImage = e.target.result;
          }
          reader.readAsDataURL(this.editBannerForm.linkedInPost);
        }else{
          this.previewLinkedInPostImage = '';
        }
      }
      console.clear();  
    },
  },
};
</script>